import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Veeliager</h1>
      </header>
      <section className="Features-section">
        
        <div className="Feature">
          <h3>About Us</h3>
          <p>Our aim is to help college students connect with each other, promoting more open information exchange and smoother communication.</p>
        </div>
        <div className="Feature">
          <h3>Our Product</h3>
          <p>Campus Alley</p>
          <p>An app designed to assist college students in buying, selling, renting apartments and carpooling</p>
        </div>
        <div className="Feature">
          <h3>Contact Us</h3>
          <p>Email: veeliager@gmail.com</p>
        </div>
      </section>
      <footer className="App-footer">
        <p>&copy; 2023 Veeliager LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
